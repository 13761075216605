<template>
  <v-menu bottom open-on-hover offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        text
        v-on="on"
        class="pl-1"
        :class="{'noW': $vuetify.breakpoint.xsOnly}"
        :color="color || 'color1Text'"
      >
        <v-avatar
          size="40"
          :class="{'mr-2': $vuetify.breakpoint.smAndUp}"
          class="elevation-3"
        >
          <img :src="user.avatarUrl" :alt="user.fullname" v-if="user.avatarUrl">
          <v-icon v-else large>fas fa-user-circle</v-icon>
        </v-avatar>
        <span class="hidden-xs-only">{{ user.fullname }}</span>
      </v-btn>
    </template>
    <!-- USER MENU -->
    <v-list dense>
      <template v-for="item in userMenu">
        <v-list-item v-if="item.to" :key="item.text" router :to="item.to">
          <v-list-item-action class="mr-3">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="item.click" :key="item.text" @click="item.click">
          <v-list-item-action class="mr-3">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['color'],
  computed: {
    ...mapGetters(['user', 'auth']),
    userMenu () {
      return [
        { icon: 'fas fa-user-circle fa-fw', text: 'My Account', to: { name: 'me' }, show: true },
        ...this.user.profiles.map(p => {
          return {
            icon: 'fas fa-id-card',
            text: p.relation === 'Self' ? 'My Player Profile' : `${p.profile.firstName}'s Player Profile`,
            to: { name: 'player-profile', params: { playerProfileId: p.profile.id } },
            show: true
          }
        }),
        { icon: 'fas fa-history fa-fw', text: 'My Orders', to: { name: 'my-orders' }, show: true },
        { icon: 'fas fa-heart fa-fw', text: 'Favorites & Notes', to: { name: 'my-bookmarks' }, show: true },
        { icon: 'fas fa-bell', text: 'Notifications', to: { name: 'my-notifications' }, show: this.user && this.user.id === 1 },
        { icon: 'fas fa-sign-out-alt fa-fw', text: 'Sign Out', click: () => { this.auth.logout(true) }, show: true }
      ].filter(f => f.show)
    }
  }
}
</script>
<style scoped>
.noW {
  padding-right: 0 !important;
  min-width: 0 !important;
}
</style>
